<template>
  <div>
    <v-container class="px-5">
      <v-row>
        <v-col cols="12">
          <div class="text-overline font-weight-bold">Wallet Balance</div>
          <div class="primary--text"><sup class="text-h6">&#8358;</sup> <span v-if="wallet == ''" class="text-h3">********</span><span v-else class="text-h3">{{wallet | formatPrice}}</span></div>
          <div class="text-caption mt-2">Wallet ID: <span class="font-weight-bold secondary--text">{{user.accounts ? user.accounts[1].account_number : ''}}</span></div>
          <div class="mt-4">
            <v-btn small class="mr-4" color="#1B2845" dark depressed to="/dashboard/savings">Quick Save</v-btn>
            <fund-wallet @walletFunded="getWalletBalance()" />
          </div>
        </v-col>
      </v-row>

    <v-container fluid style="background: #E3F2FD" class="grey lighten-5 pa-3 pa-sm-8 mt-10 rounded-lg text-center">
      <v-row>
        <v-col>
          <v-card :class="toWallet ? 'primary lighten-1 pa-4 pa-sm-5 rounded-lg white--text' : 'pa-4 pa-sm-5 secondary rounded-lg white--text'" elevation="2" @click="(toWallet = true), (toBank = false), (toBusienss = false)">
            <div class="text-subtitle-1 font-weight-bold">Transfer to Wallet</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card :class="toBank ? 'primary lighten-1 pa-4 pa-sm-5 rounded-lg white--text' : 'pa-4 pa-sm-5 secondary rounded-lg white--text'" elevation="2" @click="(toWallet = false), (toBank = true), (toBusienss = false)">
            <div class="text-subtitle-1 font-weight-bold">Transfer to Bank</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card :class="toBusienss ? 'primary lighten-1 pa-4 pa-sm-5 rounded-lg white--text' : 'pa-4 pa-sm-5 secondary rounded-lg white--text'" elevation="2" @click="(toWallet = false), (toBank = false), (toBusienss = true)">
            <div class="text-subtitle-1 font-weight-bold">Transfer to Business</div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
      <v-row class="mb-10 mt-5">
        <v-col cols="12" md="8" class="">
          <v-card class="rounded-lg pa-5 pa-md-12 py-10 grey lighten-5" flat v-if="toWallet">
            <div class="primary--text text-h5 font-weight-medium text-center">Send to a wallet account</div>
            <v-form class="mt-10" lazy-validation v-model="valid" ref="form">
              <v-text-field
                class="mb-0"
                placeholder="Wallet ID"
                v-model="wallet_id"
                solo outlined
                oninput="if(this.value.length > 10) return this.value = this.value.slice(0, 10)"
                required
                :rules="[(v) => !!v || 'This field is required']"
              >
              </v-text-field>
              <span style="margin-top: -20px !important;" 
                v-if="wallet_id && wallet_id.length == 10 && form.account_name == ''">Fetching wallet details...
                <v-progress-circular
                 :value="20"
                 color="primary"
                 indeterminate
                 :size="15"
                 :width="2"
                ></v-progress-circular>
              </span>
              <v-text-field
                v-if="form.account_name !== ''"
                outlined solo
                readonly
                v-model="form.account_name"
              ></v-text-field>

              <v-text-field
                class="mb-0"
                placeholder="Amount"
                prefix="₦"
                v-model.number="form.amount"
                outlined solo
                required
                type="number"
                oninput="if(this.value < 0) this.value = 10;"
                :rules="[(v) => !!v || 'This field is required']"
              >
              </v-text-field>
              <v-text-field
                class="mb-0"
                placeholder="Narration"
                v-model="form.description"
                outlined solo
              >
              </v-text-field>
              <v-card class="rounded-lg grey lighten-5 d-sm-block d-md-none" style="margin-top: -25px;" elevation="0" v-if="toWallet && multipleWallets.length">
                <!-- <div class="text-h6 font-weight-medium primary--text">Mulitple Transfers</div> -->
                <v-list class="grey lighten-5">
                  <v-list-item  class="rounded-lg white mb-2" v-for="(wall, index) in multipleWallets" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="d-flex">
                          <v-list-item-title v-text="'Name: ' + wall.account_name"></v-list-item-title>
                          <v-spacer></v-spacer>
                          <v-icon color="primary" @click="removeWallet(wall)">cancel</v-icon>
                        </div>
                      </v-list-item-title>
                      <v-list-item-subtitle><span>Amount: &#8358;{{ wall.amount | formatPrice }}</span></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <div class="d-block d-sm-flex">
                <v-checkbox 
                  @click="savebeneficiary('wallet')"
                  v-model="checkbox"
                  :disabled="!walletcheckbox || !wallet_id" 
                  label="Save Beneficiary" 
                  outlined
                  class="my-0 ben"
                ></v-checkbox>
                <v-btn
                  :disabled="form.account_name == '' || form.amount == ''"
                  text
                  class="mb-4 my-0 pa-0 ml-sm-5"
                  color="primary"
                  @click="addWallet()"
                ><v-icon>add</v-icon>Add another account</v-btn>
              </div>
              <v-btn
                class="primary"
                @click="$refs.form.validate() ? (pinDialog = true) : null"
                x-large
                block
                >Send</v-btn
              >
            </v-form>
          </v-card>

          <v-card class="rounded-lg pa-5 pa-md-12 py-10 grey lighten-5" elevation="0" v-if="toBank"
          >
            <div class="primary--text text-h6 font-weight-medium">Send to Bank Account</div>
            <v-form class="mt-10" lazy-validation v-model="valid" ref="form2">
              <v-text-field
                class="mb-0"
                placeholder="Account Number"
                v-model="form.account_number"
                outlined solo
                required
                oninput="if(this.value.length > 10) return this.value = this.value.slice(0, 10)"
                :rules="[(v) => !!v || 'This field is required']"
              >
              </v-text-field>
              <v-autocomplete
                v-model="bank"
                :items="banks"
                item-text="name"
                item-value="code"
                outlined
                class="mb-1"
                required
                label="Select Bank"
                :rules="[(v) => !!v || 'This field is required']"
              ></v-autocomplete>
              <span style="margin-top: -20px !important;" 
                v-if="bank !== '' && form.account_name === ''">Fetching account details...
                <v-progress-circular
                 :value="20"
                 color="primary"
                 indeterminate
                 :size="15"
                 :width="2"
                ></v-progress-circular>
              </span>
              <v-text-field
                outlined solo
                readonly
                :value="form.account_name"
                v-if="form.account_name !== ''"
              >
              </v-text-field>
              <v-text-field
                class="mb-0"
                placeholder="Amount"
                prefix="₦"
                v-model.number="form.amount"
                outlined solo
                required
                type="number"
                oninput="if(this.value < 0) this.value = 10;"
                :rules="[(v) => !!v || 'This field is required']"
              >
              </v-text-field>
              <v-text-field
                class="mb-0"
                placeholder="Narration (Optional)"
                v-model="form.description"
                outlined solo
              >
              </v-text-field>
              <v-card class="rounded-lg grey lighten-5 d-sm-block d-md-none" style="margin-top: -25px;" elevation="0" v-if="toBank && multipleAccounts.length">
                <!-- <div class="text-h6 font-weight-medium primary--text">Mulitple Transfers</div> -->
                <v-list class="grey lighten-5">
                  <v-list-item  class="rounded-lg white mb-2" v-for="(account, index) in multipleAccounts" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="d-flex">
                          <v-list-item-title v-text="'Name: ' + account.account_name"></v-list-item-title>
                          <v-spacer></v-spacer>
                          <v-icon color="primary" @click="removeAccount(account)">cancel</v-icon>
                        </div>
                      </v-list-item-title>
                      <v-list-item-subtitle><span>Amount: &#8358;{{ account.amount | formatPrice }}</span></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <div class="d-block d-sm-flex">
                <!-- <v-checkbox
                  v-model="accountbeneficiary"
                  :label="`Save Beneficiary`"
                  outlined class="my-0 ben"
                ></v-checkbox> -->
                <v-checkbox 
                  @click="savebeneficiary('bank_account')" 
                  :disabled="!accountcheckbox" 
                  :label="`Save Beneficiary`" 
                  outlined 
                  class="my-0 ben"
                ></v-checkbox>
                <v-btn :disabled="form.account_name == '' || form.amount == ''" text class="mb-4 my-0 pa-0 ml-sm-5" color="primary" @click="addAccount()">
                  <v-icon>add</v-icon>Add another account
                </v-btn>
              </div>
              <v-btn
                class="primary"
                x-large
                block
                :loading="loading"
                @click="$refs.form2.validate() ? (pinDialog = true) : null"
                >Send</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card class="rounded-lg px-3 py-5 grey lighten-5 d-md-block d-none" elevation="0" v-if="toWallet && multipleWallets.length">
            <div class="text-h6 font-weight-medium primary--text">Mulitple Transfers</div>
            <v-list nav bene class="grey lighten-5">
              <v-list-item  class="rounded-lg white mb-2" v-for="(wall, index) in multipleWallets" :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="d-flex">
                      <v-list-item-title class="secondary--text" v-text="wall.account_name"></v-list-item-title>
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle>Amount: <span class="text-body-2 font-weight-medium">&#8358;{{ wall.amount | formatPrice }}</span></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="removeWallet(wall)">
                    <v-icon color="grey lighten-1" size="20">mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
          <div class="my-4" v-if="multipleWallets.length"></div>
          <v-card class="rounded-lg px-3 py-5 grey lighten-5" elevation="0" v-if="toWallet">
            <div class="text-h6 font-weight-medium primary--text mb-2">Choose saved recipient</div>
            <div v-if="savedbeneficiary == ''">
                <div class="fill-height py-16 rounded-lg text-center white text--secondary">
                  No saved beneficiary  
                </div>
            </div>
            <v-list nav v-else class="grey bene lighten-5" two-lines>
              <v-list-item  class="rounded-lg white mb-2" v-for="benefit in savedbeneficiaries" link :key="benefit.title" @click="wallet_id = benefit.beneficiary_account_number">
                <template v-if="benefit.account_type == 'wallet'">
                  <v-list-item-avatar>
                    <img alt="avatar" :src="require('@/assets/icons/user.png')"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="secondary--text"></v-list-item-title>
                    <v-list-item-subtitle v-text="benefit.beneficiary_account_number"></v-list-item-subtitle>
                    <v-list-item-subtitle v-text="benefit.account_details.account_name"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon color="grey lighten-1" size="20">mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list>
          </v-card>

          <v-card class="rounded-lg pa-5 grey lighten-5 d-md-block d-none" elevation="0" v-if="toBank && multipleAccounts.length">
            <div class="text-h6 font-weight-medium primary--text mt-5">Mulitple Transfers</div>
            <v-list class="grey lighten-5">
              <v-list-item  class="rounded-lg white mb-2" v-for="(account, index) in multipleAccounts" :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="d-flex">
                      <v-list-item-title v-text="'Name: ' + account.account_name"></v-list-item-title>
                      <v-spacer></v-spacer>
                      <v-icon color="primary" @click="removeAccount(account)">cancel</v-icon>
                    </div>
                  </v-list-item-title>
                  <v-list-item-subtitle><span>Amount: &#8358;{{ account.amount | formatPrice }}</span></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card class="rounded-lg pa-5 grey lighten-5" elevation="0" v-if="toBank">
            <div class="text-h5 font-weight-medium primary--text mt-5 mb-5">Choose saved recipient</div>
            <div v-if="savedbankbeneficiary == ''">
                <div class="fill-height py-16 rounded-lg text-center white text--secondary">
                  No saved beneficiary  
                </div>
            </div>
            <v-list v-else class="grey lighten-5">
              <v-list-item  class="rounded-lg white mb-2" v-for="benefit in savedbeneficiaries" :key="benefit.title">
                <v-list-item-avatar>
                  <img alt="avatar" :src="require('@/assets/icons/user.png')"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="benefit.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="benefit.bank_name"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="pinDialog" width="400">
        <v-card class="pa-6 text-center">
          <h4 class="primary--text">Enter your PIN</h4>
          <p style="font-size: 17px" v-if="multipleWallets.length || multipleAccounts.length">
            Enter your PIN to complete multiple transfers
          </p>
          <p style="font-size: 17px" v-else>
            You're sending &#8358;{{ form.amount | formatPrice }} to {{ form.account_name +','}}
            Enter your PIN to complete your transaction
          </p>

          <PincodeInput v-model="form.pin" :secure="true" ref="pinCode" />

          <v-btn
            class="primary mt-12"
            block
            large
            :loading="loading"
            :disabled="form.pin.length !== 4"
            @click="
              toWallet && multipleWallets.length < 1
              ? walletTransfer()
              : toWallet && multipleWallets.length
              ? walletsTransfer()
              : toBank && multipleAccounts.length < 1
              ? transferBankAccount()
              : toBank && multipleAccounts.length
              ? banksTransfer()
              : null
            "
            >Complete Transfer</v-btn
          >
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="receiptDialog"
        max-width="450"
      >
        <v-card
          class="pa-2 pb-5"
        >
          <v-card-title class="justify-center text-break">
            <img :src="require('../../../../public/favicon.png')" height="50" alt=""> 
            <span class="secondary--text" style="margin-left: -5px;">Tran</span>
            <span class="primary--text">Save</span>
          </v-card-title>
          <v-toolbar class="d-flex justify-center" flat>TRANSACTION DETAILS</v-toolbar>
          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <div class="pa-2">
                  <v-list-item-title class="float-left" style="font-size: 12px;">Receiver Account Name:</v-list-item-title>
                  <div class="text-right primary--text float-right" style="font-size: 14px;">Emmanue Maduka</div>
                </div>
                <div class="pa-2">
                  <v-list-item-title class="float-left" style="font-size: 12px;">Destination Account Number:</v-list-item-title>
                  <span class="text-right primary--text float-right" style="font-size: 14px;">{{ user.accounts ? user.accounts[1].account_number : '' }}</span>
                </div>
                <div class="pa-2">
                  <v-list-item-title class="float-left" style="font-size: 12px;">Destination Bank:</v-list-item-title>
                  <span class="text-right primary--text float-right" style="font-size: 14px;">Zenith Bank</span>
                </div>
                <div class="pa-2">
                  <v-list-item-title class="float-left" style="font-size: 12px;">Source Account Name:</v-list-item-title>
                  <span class="text-right primary--text float-right" style="font-size: 14px;">Kenneth Eze</span>
                </div>
                <div class="pa-2">
                  <v-list-item-title class="float-left" style="font-size: 12px;">Source Account Number:</v-list-item-title>
                  <span class="text-right primary--text float-right" style="font-size: 14px;">3442xxxxxx232</span>
                </div>
                <v-divider></v-divider>
                <div class="pa-2">
                  <div class="float-left" style="font-size: 12px;">Status:</div>
                  <span class="text-break primary--text float-right" style="font-size: 14px;">Successful</span>
                </div>
                <v-divider></v-divider>
                <div class="pa-2">
                  <v-list-item-title class="float-left" style="font-size: 12px;">Tran. Date:</v-list-item-title>
                  <span class="text-right primary--text float-right" style="font-size: 14px;">{{ new Date() | tx_date }}</span>
                </div>
                <v-divider></v-divider>
                <div class="pa-2">
                  <v-list-item-title class="float-left" style="font-size: 12px;">Amount:</v-list-item-title>
                  <span class="primary--text float-right" style="font-size: 14px;">&#8358;234</span>
                </div>
                <v-divider></v-divider>
                <v-card-subtitle class="text-center dark--text">Generated By Transave</v-card-subtitle>
                <v-text-field style="opacity: 0; position: absolute;" :value="123" id="tokenID"></v-text-field>
                <v-row class="mt-4">
                  <v-card
                    color="grey darken-1"
                    width="100%"
                    class="d-flex justify-space-around"
                  >
                  <v-btn
                    text
                    icon
                    color="white"
                    class="ml-2"
                    @click="(receiptDialog = false)"
                  ><v-icon>mdi-close</v-icon></v-btn>
                  <v-btn
                    icon
                    text
                    color="white"
                    class=""
                    @click="copyToken('tokenID')"
                  ><v-icon>mdi-content-copy</v-icon></v-btn>
                  <!-- <v-btn
                    icon
                    color="white"
                    text
                    class=""
                  ><v-icon>mdi-share-variant</v-icon></v-btn> -->
                  <v-btn
                    icon
                    color="white"
                    text
                    class="mr-2"
                    @click="generateReport()"
                  ><v-icon>mdi-file-download-outline</v-icon></v-btn>
                  </v-card>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        :pdf-quaility="2"
        :manual-pagination="false"
        pdf-format="a6"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        @hasStartedGeneration="hasGenerated($event)"
        :html-to-pdf-options="{
          filename: 'Transave Transaction Receipt',
          html2canvas: {
            scrollX: 0,
            scrollY: 0,
            scale: 5,
          },
          jsPDF: {
            unit: 'in',
            format: 'a6',
            orientation: 'portrait'
          },
        }"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <v-card
            class="pa-2 pb-5"
          >
            <v-card-title style="justify-content: center;">
              <img :src="require('../../../../public/favicon.png')" height="50" alt=""> 
              <span style="margin-left: -5px; color: #C1A13D;">Tran</span>
              <span style="color: #171B70;">Save</span>
            </v-card-title>
            <v-toolbar style="display: flex; justify-content: center;" flat>TRANSACTION DETAILS</v-toolbar>
            <v-divider></v-divider>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <div class="pa-2">
                    <v-list-item-title style="float: left; font-size: 12px;">Receiver Account Name:</v-list-item-title>
                    <div class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">Emmanue Maduka</div>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title style="float: left; font-size: 12px;">Destination Account Number:</v-list-item-title>
                    <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ user.accounts ? user.accounts[1].account_number : '' }}</span>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title style="float: left; font-size: 12px;">Destination Bank:</v-list-item-title>
                    <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">Zenith Bank</span>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title style="float: left; font-size: 12px;">Source Account Name:</v-list-item-title>
                    <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">Kenneth Eze</span>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title style="float: left; font-size: 12px;">Source Account Number:</v-list-item-title>
                    <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">3442xxxxxx232</span>
                  </div>
                  <v-divider></v-divider>
                  <div class="pa-2">
                    <div style="float: left; font-size: 12px;">Status:</div>
                    <span class="text-break primary--text" style="float: right; font-size: 14px; color: #171B70;">Successful</span>
                  </div>
                  <v-divider></v-divider>
                  <div class="pa-2">
                    <v-list-item-title style="float: left; font-size: 12px;">Tran. Date:</v-list-item-title>
                    <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ new Date() | tx_date }}</span>
                  </div>
                  <v-divider></v-divider>
                  <div class="pa-2">
                    <v-list-item-title style="float: left; font-size: 12px;">Amount:</v-list-item-title>
                    <span class="primary--text" style="float: right; font-size: 14px; color: #171B70;">&#8358;234</span>
                  </div>
                  <v-divider></v-divider>
                  <v-card-subtitle style="text-align: center; color: #333;">Generated By Transave</v-card-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </section>
      </vue-html2pdf>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        filename="Transave Transaction Receipt"
        :pdf-quaility="2"
        :manual-pagination="false"
        pdf-format="a6"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <v-card
              class="pa-8 pb-5"
            >
              <v-card-title style="justify-content: center;">
                <img :src="require('../../../../public/favicon.png')" height="50" alt=""> 
                <span style="margin-left: -5px; color: #C1A13D;">Tran</span>
                <span style="color: #171B70;">Save</span>
              </v-card-title>
              <v-toolbar style="display: flex; justify-content: center;" flat>TRANSACTION DETAILS</v-toolbar>
              <v-divider></v-divider>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Receiver Account Name:</v-list-item-title>
                      <div class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">Emmanue Maduka</div>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Destination Account Number:</v-list-item-title>
                      <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ user.accounts ? user.accounts[1].account_number : '' }}</span>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Destination Bank:</v-list-item-title>
                      <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">Zenith Bank</span>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Source Account Name:</v-list-item-title>
                      <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">Kenneth Eze</span>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Source Account Number:</v-list-item-title>
                      <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">3442xxxxxx232</span>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-2">
                      <div style="float: left; font-size: 12px;">Status:</div>
                      <span class="text-break primary--text" style="float: right; font-size: 14px; color: #171B70;">Successful</span>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Tran. Date:</v-list-item-title>
                      <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ new Date() | tx_date }}</span>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Amount:</v-list-item-title>
                      <span class="primary--text" style="float: right; font-size: 14px; color: #171B70;">&#8358;234</span>
                    </div>
                    <v-divider></v-divider>
                    <v-card-subtitle style="text-align: center; color: #333;">Generated By Transave</v-card-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
        </section>
      </vue-html2pdf>
    </v-container>
  </div>
</template>
<script>
import FundWallet from '@/components/FundWallet.vue'
import { mapGetters } from 'vuex'
import PincodeInput from 'vue-pincode-input'
export default {
  components: {
    PincodeInput,
    FundWallet,
  },
  data() {
    return {
      toWallet: true,
      toBank: false,
      toBusienss: false,
      valid: true,
      loading: false,
      pinDialog: false,
      bank: '',
      wallet: '',
      wallet_id: '',
      banks: [],
      form: {
        pin: '',
        account_name: '',
        account_number: '',
        description: '',
        amount: '',
      },
      checkbox: false,
      walletcheckbox: false,
      accountcheckbox: false,
      savedbeneficiary: [],
      selectedben: '',
      savedwalletbeneficiary: [],
      savedbankbeneficiary: [],
      multipleWallets: [],
      multipleAccounts: [],
      receiptDialog: false,
    }
  },
  watch: {
    account_number: function () {
      if (this.bank && this.form.account_number.length == 10) {
        this.validateAccountNumber()
      }
    },

    bank: function () {
      if (this.bank && this.form.account_number.length == 10) {
        this.validateAccountNumber();
        this.form.bank_name = this.banks.find(
          (x) => x.code === this.bank
        ).name
      }
    },

    wallet_id: function () {
      if (this.wallet_id && this.wallet_id.length == 10) {
        this.validateWalletId()
      } else if (this.wallet_id && this.wallet_id.length < 10) {
        this.form.account_name = '';
        this.checkbox = false
      }
    },

    pinDialog: function () {
      if (this.pinDialog == false) {
        this.form.pin = ''
      }
    },
  },
  created() {
    this.getBanks();
    this.getWalletBalance();
    this.getBeneficiaries();
  },
  computed: {
    ...mapGetters('auth', ['user', 'withdrawal_limit']),

    savedbeneficiaries() {
      return this.savedbeneficiary.filter(x => {
        if (x.account_details !== null) {
          return true
        }
        return false
      })
    }
  },
  
  methods: {
    async getBanks() {
      try {
        this.banks = await this.$store.dispatch('transfer/getallbanks');
      }
      catch (err) {
        this.$toast.error(err.response.data.message)
      }
    },

    async getWalletBalance() {
      const data = {
        user_id: this.user.id,
      }
      try {
        let response = await this.$store.dispatch('auth/wallet', data);
        if (response == 0) {
          this.wallet = '0'
        } else {
          this.wallet = response
        }
      }
      catch(err) {
        
      }
    },

    async walletTransfer() {
      if (this.form.amount > this.withdrawal_limit) {
        this.pinDialog = false, this.$toast.error('Your maximum transaction limit is ₦' + this.$options.filters.formatPrice(this.withdrawal_limit) + ', Update your KYC level to transact more.')
      }

      else if (this.wallet_id === this.user.accounts[1].account_numbername) {
        this.pinDialog = false, this.wallet_id = '';
        this.form.account_name = '';
        this.$toast.error('Invalid account number, you cannot transfer to self account!');
      }

      else if (this.form.amount < 10)  {
        this.$toast.error('Minimum transfer amount is ₦10');
        this.pinDialog = false;
      }

      else {
        this.loading = true;
        const data = {
          user_id: this.user.id,
          pin: this.form.pin,
          amount: this.form.amount,
          account_number: this.wallet_id,
          description: this.form.description,
        };
        try {
          let response = await this.$store.dispatch('transfer/wallettowallet', data);
          this.$toast.success(response.message);
          this.loading = false;
          this.pinDialog = false;
          this.$refs.form.reset();
          this.getWalletBalance();
        }
        catch(err) {
          this.loading = false;
          this.pinDialog = false;
          if (err.response.status === 422) {
            let messages = err.response.data.message;
            let errors = Object.values(messages);
            errors = errors.flat();
            errors.forEach(error => {
              this.$toast.error(error)
            });
          } else {
            this.$toast.error(err.response.data.message)
          }
        }
      }
    },

    async validateWalletId() {
      this.loading = true;
      const data = {
        wallet_id: this.wallet_id
      };
      try {
        let response = await this.$store.dispatch('transfer/resolvewalletid', data);
        this.loading = false;
        this.form.account_name = response.name;
        this.walletcheckbox = true;
      }
      catch (err) {
        this.loading = false;
        this.walletcheckbox = false;
        setTimeout(() => {
          this.$toast.error('Please verify the wallet ID or check your internet connection.')
        }, 5000)
      }
    },

    async validateAccountNumber() {
      const data = {
        account_number: this.form.account_number,
        bank_code: this.bank,
      };
      try {
        let response = await this.$store.dispatch('transfer/resolveaccount', data);
        this.form.account_name = response.account.name;
        this.form.recipient_code = response.account.clientId;
        this.accountcheckbox = true;
      }
      catch(err) {
        this.accountcheckbox = false;
        setTimeout(() => {
          this.$toast.error('Please check the account details or your internet connection')
        }, 5000)
        // console.log(err)
      }
    },

    async transferBankAccount() {
      if (this.form.amount > this.withdrawal_limit) {
        this.pinDialog = false, this.$toast.error('Your maximum transaction limit is ₦' + this.$options.filters.formatPrice(this.withdrawal_limit) + ', Update your KYC level to transact more.')
      }

      else if (this.form.account_name == '') {
        this.$toast.error('Unable to fetch recipent')
      }

      else if (this.form.account_number === this.user.accounts[0].account_number) {
        this.$toast.error('You cannot transfer to self account!');
        this.pinDialog = false;
        this.form.account_number = '';
        this.form.account_name = '';
      }

      else if (this.form.amount < 10) {
        this.$toast.error('Minimum transfer amount is ₦10');
        this.pinDialog = false;
      }

      else {
        this.loading = true;
        const data = {
          amount: this.form.amount,
          reason: this.form.description,
          recipient_account: this.form.account_number,
          user_id: this.user.id,
          pin: this.form.pin,
          transfer_type: 'inter',
          bank_code: this.bank,
          account_name: this.form.account_name,
          bank_name: this.form.bank_name,
          receiver_account_name: this.form.account_name,
        };
        try {
          let response = await this.$store.dispatch('transfer/transfertobank', data);
          this.$toast.success(response.message);
          this.loading = false;
          this.$refs.form2.reset();
          this.pinDialog = false;
          this.getWalletBalance();
          this.$refs.pinDialog.reset();
        }
        catch(err) {
          this.form.pin = ''
          this.loading = false
          this.pinDialog = false
          this.$refs.pinCode.reset()
          if (err.response.status === 422) {
            let messages = err.response.data.errors
            let errors = Object.values(messages)
            console.log(errors)
            errors = errors.flat()
            errors.forEach(error => {
              this.$toast.error(error)
            });
          } else {
            this.$toast.error(err.response.data.message)
          }
        }
      }
    },
    async getBeneficiaries() {
      const data = {
        user_id: this.user.id
      }
      try {
        let res = await this.$store.dispatch('transfer/beneficiaries', data);
        this.savedbeneficiary = res.beneficiaries;
      } catch(err) {
        this.savedbeneficiary = [];
        
      }
    },

    async savebeneficiary(type) {
      if(this.checkbox) {
        let ac_num = '';
          if(type == 'wallet') {
            ac_num = this.wallet_id
          } else {
            ac_num = this.form.account_number
          }
          const data = {
            user_id: this.user.id,
            account_type: type,
            account_number: ac_num
          }
          try {
            let res = await this.$store.dispatch('transfer/savebeneficiary', data);
            this.$toast.success(res.message);
            this.getBeneficiaries();
          } catch(err) {
            this.$toast.error('Invalid account number!');
          }
      }
    },

    async removebeneficiary(account_number) {
      const data = {
        user_id: this.user.id,
        account_number: account_number
      };
      try {
        let res = await this.$store.dispatch('transfer/removebeneficiary', data);
        this.$toast.success(res.message);
        this.getBeneficiaries()
      } catch(err) {
        
        //this.$toast.error('Invalid account number!')
      }
    },

    addWallet() {
      let data = {
        user_id: this.user.id,
        account_number: this.wallet_id,
        account_name: this.form.account_name,
        amount: this.form.amount,
        reason: this.form.description,
      };
      this.multipleWallets.push(data)
    },

    removeWallet(wall) {
      this.multipleWallets.splice(this.multipleWallets.indexOf(wall), 1)
    },

    async walletsTransfer() {
      if (this.form.amount > this.withdrawal_limit) {
        this.pinDialog = false, this.$toast.error('Your maximum transaction limit is ₦' + this.$options.filters.formatPrice(this.withdrawal_limit) + ', Update your KYC level to transact more.')
      }

      else if (this.wallet_id === this.user.accounts[1].account_numbername) {
        this.pinDialog = false, this.wallet_id = '';
        this.form.account_name = '';
        this.$toast.error('Invalid account number, you cannot transfer to self account!');
      }

      else if (this.form.amount < 10) {
        this.$toast.error('Minimum transfer amount is ₦10');
        this.pinDialog = false;
      }

      else {
        this.loading = true;
        let data = {
          wallets: this.multipleWallets,
          pin: this.form.pin
        };
        try {
          let response = await this.$store.dispatch('transfer/multiplewalletstransfer', data);
          this.$toast.success(response.message);
          this.pinDialog = false;
          this.loading = false;
          this.$refs.form.reset();
          this.multipleWallets = [];
          this.getWalletBalance();
        }
        catch (err) {
          this.pinDialog = false;
          this.loading = false;
          if (err.response.status == 422 || 400) {
            let messages = err.response.data;
            let errors = Object.values(messages);
            errors = errors.flat();
            errors.forEach(error => {
              this.$toast.error(error)
            });
          } else {
            this.$toast.error(err.response.data.message)
          }
        }
      }
    },

    addAccount() {
      let data = {
        user_id: this.user.id,
        account_name: this.form.account_name,
        recipient: this.form.recipient_code,
        amount: this.form.amount,
        reason: this.form.description
      };
      this.multipleAccounts.push(data)
    },

    removeAccount(account) {
      this.multipleAccounts.splice(this.multipleAccounts.indexOf(account), 1)
    },

    async banksTransfer() {
      if (this.form.amount > this.withdrawal_limit) {
        this.pinDialog = false, this.$toast.error('Your maximum transaction limit is ₦' + this.$options.filters.formatPrice(this.withdrawal_limit) + ', Update your KYC level to transact more.')
      }

      else if (this.form.account_name == '') {
        this.$toast.error('Unable to fetch recipent')
      }

      else if (this.form.account_number === this.user.accounts[0].account_number) {
        this.$toast.error('You cannot transfer to self account!');
        this.pinDialog = false;
        this.form.account_number = '';
        this.form.account_name = '';
      }

      else if (this.form.amount < 10) {
        this.$toast.error('Minimum transfer amount is ₦10');
        this.pinDialog = false;
      }

      else {
        this.loading =true;
        let data = {
          accounts: this.multipleAccounts,
          pin: this.form.pin
        };
        try {
          let response = await this.$store.dispatch('transfer/multiplebanktransfer', data)
          console.log(response)
          this.pinDialog = false
          this.getWalletBalance()
        }
        catch (err) {
          // console.log(err)
          this.pinDialog = false

        }
      }
    },

    generateReport() {
      this.$refs.html2Pdf.generatePdf()
      this.receiptDialog = false
    }
  }
}

</script>
<style lang="scss" scoped>
  .page-card {
    background: #ffffff;
    border: 1px solid #fdeaea;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 5px;
    h4 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 45px;
      color: #171b70;
    }
  }
  .v-list--nav .v-list-item, .v-list--nav .v-list-item::before {
      border-radius: 8px !important;
  }
</style>
<style>
  .ben .v-input__control .v-input__slot {
    box-shadow: none !important;
  }
  .bene {
		max-height: 400px !important;
		overflow-y: auto !important;
	}
</style>
